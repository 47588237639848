<template>
    <b-tab key="1" :title="$t('client')" active>
        <b-row>
            <b-col sm="6">
                <h5 v-if="editOn">Пошук по номеру телефону</h5>
                <div v-if="editOn" class="form-group">
                    <label for="client">{{ $t('client') }}<span class="text-danger">*</span></label>
                    <model-list-select
                        :list="customers"
                        option-value="id"
                        option-text="phone"
                        v-model="selectedCustomer"
                        :placeholder="$t('nothing_selected')"
                        @searchchange="searchClient"></model-list-select>
                </div>
                <b-button v-if="!editOn" variant="outline-success" size="sm" @click="checkEditRecipient('SET_EDITABLE_RECIPIENT')">{{ $t('edit') }}</b-button>
            </b-col>
        </b-row>
        <h5>Редагування даних клієнта</h5>
        <b-row>
            <b-col sm="6">
                <div class="form-group">
                    <label for="first_name">{{ $t('first_name') }}<span class="text-danger">*</span></label>
                    <input type="text" name="first_name" class="form-control" v-model="orderData.client.first_name" :disabled="!editOn">
                </div>
                <div class="form-group">
                    <label for="last_name">{{ $t('last_name') }}<span class="text-danger">*</span></label>
                    <input type="text" name="last_name" class="form-control" v-model="orderData.client.last_name" :disabled="!editOn">
                </div>
                <div class="form-group">
                    <label for="surname">{{ $t('patronimic_name') }}<span class="text-danger">*</span></label>
                    <input type="text" name="surname" class="form-control" v-model="orderData.client.surname" :disabled="!editOn">
                </div>
                <div class="form-group">
                    <label for="phone">{{ $t('phone') }}<span class="text-danger">*</span></label>
                    <input v-model="orderData.client.phone" v-mask="mask" class="form-control" :disabled="!editOn"/>
                </div>
                <div class="form-group">
                    <label for="email">{{ $t('email') }}</label>
                    <input type="text" name="email" class="form-control" v-model="orderData.client.email" :disabled="!editOn">
                </div>
            </b-col>
            <b-col sm="6">
                <div class="form-group">
                    <label for="client_contact">{{ $t('client_contact') }}</label>
                    <select name="client_contact" class="form-control" v-model="orderData.client.contact_type" :disabled="!editOn">
                        <option value="Viber">Viber</option>
                        <option value="WhatsApp">WhatsApp</option>
                        <option value="WhatsApp">Instagram</option>
                        <option value="Messanger">Messanger</option>
                        <option value="Телефон">Телефон</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="contact">{{ $t('contact') }}</label>
                    <input type="text" name="contact" class="form-control" v-model="orderData.client.contact" :disabled="!editOn">
                </div>
                <div class="form-group">
                    <label for="group">{{ $t('group') }}</label>
                    <select name="group" class="form-control" v-model="orderData.client.group_id" :disabled="!editOn">
                        <option v-for="(item, index) in groups" :value="item.id" :key="index">{{ item.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="company">{{ $t('company') }}</label>
                    <input type="text" name="company" class="form-control" v-model="orderData.client.company" :disabled="!editOn">
                </div>
                <div class="form-group">
                    <label for="description">{{ $t('description') }}</label>
                    <textarea name="description" class="form-control" v-model="orderData.client.description" :disabled="!editOn"></textarea>
                </div>
            </b-col>
        </b-row>
        <b-button v-if="editOn" variant="success" @click="updateRecipient">{{ $t('save') }}</b-button>
        <!--<b-button v-if="!btnSaveStatus && editOn" variant="success" @click="updateRecipient">{{ $t('save') }}</b-button>-->
    </b-tab>
</template>
<script>
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'
import { mapActions, mapGetters } from 'vuex'
export default {
    data: () => ({
        mask: ['+38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        customers: [],
        selectedCustomer: {},
        searchText: '',
        groups: null
    }),
    components: {
        ModelListSelect
    },
    mounted () {
        this.fetchGroups()
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            editOn: 'order/editRecipientData',
            printed: 'order/printed'
        }),
        btnSaveStatus () {
            if (this.printed) {
                return true
            }
            return false
        }
    },
    watch: {
        selectedCustomer: function (val) {
            this.orderData.client.id = val.id
            this.orderData.client.first_name = val.first_name
            this.orderData.client.last_name = val.last_name
            this.orderData.client.surname = val.surname
            this.orderData.client.phone = val.phone
            this.orderData.client.email = val.email
            this.orderData.client.group_id = val.group_id
            this.orderData.client.company = val.company
            this.orderData.client.description = val.description
            this.orderData.client.addresses = val.addresses.data
        }
    },
    methods: {
        ...mapActions({
            editCheck: 'order/editCheck',
            updateOrder: 'order/updateOrder',
            deliveryRedirecting: 'order/deliveryRedirecting',
            clientEditCheck: 'order/clientEditCheck'
        }),
        checkEditRecipient (type) {
            const id = this.$route.params.id
            if (this.printed) {
                this.$root.$refs.loading.start()
                this.clientEditCheck(id)
                this.$root.$refs.loading.finish()
            } else {
                this.editCheck(type)
            }
        },
        updateRecipient () {
            const id = this.$route.params.id
            if (this.printed) {
                // this.clientEditCheck(id)
            } else {
                this.updateOrder({ id: id, type: 'EDIT_CLIENT' })
            }
        },
        async searchClient (value) {
            if (value.length > 5) {
                this.searchText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'customers/search', { phone: value }).then(resp => {
                    this.customers = resp.data.data
                })
            }
        },
        async fetchGroups () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'customer-groups')
            this.groups = data.data
        },
        onSelectSearch () {
            console.log(this.selectedCustomer)
        }
    }
}
</script>
