<template>
<div class="table-responsive">
    <b-button v-if="!editOn" variant="outline-success" size="sm" @click="editCheck('SET_EDITABLE_DELIVERY_STATUS')" class="mb-2">{{ $t('edit') }}</b-button>
    <b-button v-if="editOn" variant="success" @click="updateDeliveryStatus">{{ $t('save') }}</b-button>
    <table class="table table-striped mt-2">
        <tbody>
            <tr>
                <td>
                    <div class="form-group">
                        <label for="status">{{ $t('status') }}<span class="text-danger">*</span></label>
                        <select name="status" class="form-control" v-model="orderData.deliverySummary.orderStatus" :disabled="!editOn || setDisabledOrderSelect">
                            <option>{{ $t('nothing_selected') }}</option>
                            <option v-for="(item, index) in orderStatuses" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label for="payment">{{ $t('payment') }}<span class="text-danger">*</span></label>
                        <select @change="setPaymentStatuses($event)" name="status" class="form-control" v-model="orderData.deliverySummary.payment" :disabled="!editOn">
                            <option v-for="(item, index) in payments" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </td>
                <td v-if="orderData.delivery.delivery_method !== 'samoviviz'">
                    <div class="form-group">
                        <label for="payment">{{ $t('pays_for_delivery') }}<span class="text-danger">*</span></label>
                        <select name="status" class="form-control" v-model="orderData.deliverySummary.payForDelivery" :disabled="!editOn">
                            <option v-for="(item, index) in paysDelivery" :key="index" :value="item.id">{{ item.text }}</option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label for="payment_status">{{ $t('payment_status') }}<span class="text-danger">*</span></label>
                        <select @change="changePaymentStatus" name="status" class="form-control" v-model="orderData.deliverySummary.paymentStatus" :disabled="!editOn">
                            <option v-for="(item, index) in paymentStatuses" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </td>
                <td width="180">
                    <div class="form-group">
                        <label for="">{{ $t('deposit') }}</label>
                        <select class="form-control" v-model="orderData.deliverySummary.depositPaymentType" :disabled="!editOn">
                            <option value=""> -- </option>
                            <option value="cash">{{ $t('cash') }}</option>
                            <option value="card">{{ $t('card') }}</option>
                        </select>
                    </div>
                </td>
                <td v-if="orderData.deliverySummary.depositPaymentType !== ''">
                    <div class="form-group">
                        <label for="">{{ $t('payment_status') }}</label>
                        <select class="form-control" v-model="orderData.deliverySummary.depositPaymentStatus" @change="changeDepositStatus" :disabled="!editOn">
                            <option value="paid">{{ $t('paid') }}</option>
                            <option value="unpaid">{{ $t('unpaid') }}</option>
                        </select>
                    </div>
                </td>
                <td v-if="orderData.deliverySummary.depositPaymentType !== ''" width="100">
                    <div class="form-group">
                        <label for="deposit">{{ $t('deposit') }}</label>
                        <input @change="changeDeposit" type="text" name="deposit" class="form-control" v-model.number="orderData.deliverySummary.deposit" :disabled="!editOn">
                    </div>
                </td>
                <td width="120">
                    <div class="form-group">
                        <label for="to_pay">{{ $t('to_pay') }}</label>
                        <input type="text" name="to_pay" class="form-control" v-model.number="orderData.summary.deposit_total" disabled>
                    </div>
                </td>
                <td style="width: 150px">
                    <div class="form-group">
                        <label for="total_dropshipper">{{ $t('total_dropshipper') }}</label>
                        <input type="text" name="total_dropshipper" class="form-control" v-model.number="orderData.summary.total_drop" disabled>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td v-if="orderData.deliverySummary.delivery_date" colspan="2">
                    <strong>{{ $t('delivery_date') }}:</strong>
                    <br/>
                    <span class="text-success">{{ orderData.delivery_date }}</span>&nbsp;
                    <b-button :disabled="delivery_btn_loading" pill variant="outline-danger" size="sm" @click="updateDeliveryDate"><fa icon="sync" /></b-button>
                </td>
                <td class="text-right" colspan="6">
                    <p><strong>{{ $t('delivery_cost') }}:</strong> {{ orderData.summary.delivery_cost | currency }}</p>
                    <p><strong>{{ $t('grand_total') }}:</strong> {{ orderData.summary.total | currency }}</p>
                    <p><strong>{{ $t('total_with_delivery') }}:</strong> {{ orderData.summary.total + orderData.summary.delivery_cost | currency }}</p>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    data: () => ({
        paysDelivery: [
            {
                id: 'sender',
                text: 'Відправник',
                selected: false
            },
            {
                id: 'recipient',
                text: 'Одержувач',
                selected: true
            }
        ],
        delivery_btn_loading: false
    }),
    mounted () {
        this.setDeliveries()
        this.setPayments()
        this.setStatuses()
        setTimeout(() => {
            this.updateStatuses()
        }, 1000)
    },
    computed: {
        ...mapGetters({
            deliveries: 'order/deliveries',
            payments: 'order/paymentsToDelivery',
            statuses: 'order/statuses',
            orderData: 'order/order',
            totalPrice: 'order/totalPrice',
            deliveryData: 'order/deliveryData',
            orderStatuses: 'order/orderStatuses',
            paymentStatuses: 'order/paymentStatuses',
            editOn: 'order/editDeliveryStatusData',
            setDisabledOrderSelect: 'order/setDisabledOrderSelect'
        }),
        orderTotal () {
            const products = this.order.products.data
            let total = 0
            let totalDrop = 0
            products.forEach(p => {
                totalDrop += Number.parseFloat(p.total_price_drop)
            })
            total = totalDrop - this.order.delivery_total
            return { total: total, total_drop: totalDrop }
        }
    },
    methods: {
        ...mapActions({
            setDeliveries: 'order/setDeliveries',
            setPayments: 'order/setPayments',
            setStatuses: 'order/setStatuses',
            setOrder: 'order/setOrder',
            updateStatuses: 'order/updateStatuses',
            setPaymentStatuses: 'order/setPaymentStatuses',
            editCheck: 'order/editCheck',
            updateOrder: 'order/updateOrder',
            updateTotalPrice: 'order/updateTotalPrice',
            setOrderStatusByRelation: 'order/setOrderStatusByRelation'
        }),
        ...mapMutations({
            setDisableOrderStatus: 'order/SET_DISABLED_ORDER_STATUS'
        }),
        updateDeliveryStatus () {
            const id = this.$route.params.id
            this.updateOrder({ id: id, type: 'EDIT_DELIVERY_STATUS' })
        },
        async updateDeliveryDate () {
            this.delivery_btn_loading = true
            this.$root.$refs.loading.start()
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const products = this.order.products.data.map(p => {
                return p.id
            })
            if (this.order.delivery_method === 'nova-posta') {
                const citySender = this.deliveries.find(d => d.slug === this.order.delivery_method)
                const { data } = await axios.post(apiUrl + 'deliveries/price', {
                    products: products,
                    citySender: citySender.city_ref,
                    cityRecipient: this.order.delivery_address.city_recipient,
                    deliveryType: this.order.delivery_address.delivery_type
                })
                this.order.delivery_total = data.deliveryCost
                this.order.delivery_date = data.deliveryDate
                this.$root.$refs.loading.finish()
            }
            this.delivery_btn_loading = false
        },
        changeDeposit () {
            this.updateTotalPrice()
            if (this.orderData.deliverySummary.depositPaymentStatus === 'paid') {
              this.orderData.deliverySummary.depositDatetime = new Date().toISOString()
            }
        },
        changePaymentStatus () {
            const status = this.paymentStatuses.find(p => p.id === this.orderData.deliverySummary.paymentStatus)
            if (status.name === 'Частково оплачено') {
                this.orderData.deliverySummary.deposit_val = 'minus'
            } else if (status.name === 'Частково неоплачено') {
                this.orderData.deliverySummary.deposit_val = 'plus'
            } else if (status.name === 'Неоплачено') {
                this.orderData.deliverySummary.deposit = 0
            }
            this.orderData.deliverySummary.paymentStatusDatetime = new Date().toISOString()
            // this.orderData.deliverySummary.deposit = 0
            this.updateTotalPrice()
            this.setOrderStatusByRelation()
        },
        changeDepositStatus (e) {
            const val = e.target.value
            if (val === 'unpaid') {
                // change order status
                const orderStatuses = this.statuses.filter(s => s.type === 'order')
                this.orderData.deliverySummary.orderStatus = orderStatuses.find(s => s.name === 'Робити пізніше').id
                this.setDisableOrderStatus(true)
                this.orderData.deliverySummary.depositDatetime = null
            } else {
                const orderStatuses = this.statuses.filter(s => s.type === 'order')
                this.orderData.deliverySummary.orderStatus = orderStatuses.find(s => s.name === 'Новий').id
                this.setDisableOrderStatus(false)
                // if status === paid set datetime
                this.orderData.deliverySummary.depositDatetime = new Date().toISOString()
            }
            this.updateTotalPrice()
        },
        updateComponentKey () {
            this.$nextTick(() => {
                // this.forceRerender()
                this.$forceUpdate()
            })
        },
        forceRerender () {
            this.componentKey += 1
            this.componentKeyDelivery += 1
        }
    }
}
</script>
