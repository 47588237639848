<template>
<b-card v-if="orderData" :title="`${$t('edit')}: ${$t('order')} #${orderData.orderNum}`">
    <b-alert v-if="status" :variant="typeOfAlert" show dismissible class="mt-2" @dismissed="closeAlert">{{ msg }}</b-alert>
    <b-tabs content-class="mt-3">
        <client/>
         <b-tab :title="$t('package')">
            <delivery/>
            <deliveryStatus/>
            <product/>
        </b-tab>
        <b-tab :title="$t('additional')">
            <div class="form-group">
                <label for="comment">{{ $t('comment') }}</label>
                <textarea type="text" name="mark" class="form-control" v-model="orderData.comment"></textarea>
            </div>
        </b-tab>
    </b-tabs>
    <!--<b-button variant="success" @click="edit">{{ $t('save') }}</b-button>-->
</b-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import Client from '~/pages/orders/edit/client'
import Delivery from '~/pages/orders/edit/delivery'
import Product from '~/pages/orders/edit/products'
import DeliveryStatus from '~/pages/orders/edit/deliveryStatus'
import Swal from 'sweetalert2'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('edit') }
    },
    components: {
      Client,
      Delivery,
      DeliveryStatus,
      Product
    },
    mounted () {
        this.setDeliveries()
        this.setPayments()
        this.getOrder()
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            summary: 'order/orderSummary',
            msg: 'order/msg',
            status: 'order/statusEdit',
            typeOfAlert: 'order/typeOfAlert'
        })
    },
    methods: {
        ...mapMutations({
            setEdittable: 'order/SET_EDITABLE'
        }),
        ...mapActions({
            setOrder: 'order/setOrder',
            setDeliveries: 'order/setDeliveries',
            setPayments: 'order/setPayments',
            checkReneval: 'order/checkReneval',
            closeAlert: 'order/closeEditAlert'
        }),
        async getOrder () {
            const id = this.$route.params.id
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'orders/' + id)
            const resp = data.data
            this.setOrder(resp)
        },
        async edit () {
            if (this.orderData.products.length > 0) {
                this.sendingOrderStatus = true
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                const id = this.$route.params.id
                
                await axios.put(apiUrl + 'orders/' + id, this.summary).then(resp => {
                    if (resp.data.status) {
                        this.$router.push({ name: 'orders' })
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: this.$t('error_product_not_selected'),
                    text: this.$t('error_product_not_selected_text'),
                    reverseButtons: true,
                    confirmButtonText: this.$t('ok'),
                    cancelButtonText: this.$t('cancel')
                })
            }
        },
        setEditable () {
            // refactor
            setTimeout(() => {
                if (this.orderData) {
                    const delivery = this.orderData.delivery
                    if (delivery.delivery_method === 'samoviviz' && delivery.orderStatusName !== 'Упаковано') {
                        this.setEdittable(true)
                    } else if(delivery.delivery_method === 'nova-posta') {
                        this.checkReneval()
                    }
                }
            }, 1000)
        }
    }
}
</script>
